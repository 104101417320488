<template>
  <div id="SpecialPage">
    <div class="hero is-medium is-primary is-bold">
      <div
        class="hero-body center-cover-bg-image"
        :style="
          'background-image: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(covers/about-cover.jpg);'
        "
      >
        <div class="container">
          <h1 class="title">{{ $t("special.title") }}</h1>
          <h2 class="subtitle">
            {{ $t("special.subtitle") }}
          </h2>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container has-text-left">
        <h1 class="title is-spaced">{{ $t("special.header") }}</h1>
        <p class="subtitle is-6">{{ $t("special.content") }}</p>
      </div>
      <div class="container" style="padding-top: 2rem;">
        <div class="columns is-multiline">
          <!-- PRODUCT - 1 -->
          <div class="column is-12">
            <div class="section no-padding-bottom">
              <div class="columns is-mobile is-multiline">
                <div class="column is-4-desktop is-4-tablet is-12-mobile">
                  <figure class="image is-square">
                    <img src="../assets/customs/custom-golem.jpg" />
                  </figure>
                </div>
                <div class="column has-text-left">
                  <h1 class="title is-4 is-spaced">Go Golem</h1>
                  <p class="subtitle is-6">
                    <b>Mini</b> (7.5 cm.) <br />
                    Material : PVC ABS & Magnet<br /><br />
                    <b>Originale</b> (14 cm.)<br />
                    Material : PVC ABS & Magnet<br /><br />
                    <b>Grande</b> (28 cm.) <br />
                    Material : Soft Vinyl PVC & Magnet<br />
                    (Eco-friendly PVC)<br /><br />
                    3 movable parts and 1 change position part in all series<br />
                  </p>
                  {{ $t("special.product_1.download_detail") }}
                  <a href="">{{ $t("special.download") }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- PRODUCT - 2 -->
          <div class="column is-12">
            <div class="section no-padding-bottom ">
              <div class="columns is-mobile is-multiline">
                <div class="column is-4-desktop is-4-tablet is-12-mobile">
                  <figure class="image is-square">
                    <img src="../assets/customs/custom-trex.jpg" />
                  </figure>
                </div>
                <div class="column has-text-left">
                  <h1 class="title is-4 is-spaced">THAIRANNOSAURUS REX</h1>
                  <p class="subtitle is-6">
                    Material : Soft Vinyl (Eco-friendly PVC)<br /><br />
                    Height 20 cm.<br />
                    Length 40 cm.<br /><br />
                    5 movable parts
                  </p>
                  {{ $t("special.product_2.download_detail") }}
                  <a href="">{{ $t("special.download") }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- PRODUCT - 3 -->
          <div class="column is-12">
            <div class="section no-padding-bottom">
              <div class="columns is-mobile is-multiline">
                <div class="column is-4-desktop is-4-tablet is-12-mobile">
                  <figure class="image is-square">
                    <img src="../assets/customs/custom-betta.jpg" />
                  </figure>
                </div>
                <div class="column has-text-left">
                  <h1 class="title is-4 is-spaced">LEAPING BETTA</h1>
                  <p class="subtitle is-6">
                    Material : Resin<br /><br />
                    Height 25 cm.<br />
                    Width 20 cm
                  </p>
                  {{ $t("special.product_3.download_detail") }}
                  <a href="">{{ $t("special.download") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="section">
        <div class="container has-text-left" style="padding-top: 2rem;">
          <div class="columns">
            <div class="column">
              <h1 class="title is-4">{{ $t("special.contact_us") }}</h1>
              <p class="subtitle is-6" style="text-align: left;">
                BLUSHING OWL STUDIO CO.,LTD<br />
                18/93 Soi Khuborn27 Khuborn Rd. Tharaeng Bangkaen Bangkok
                Thailand Post code 10220<br />
                Email : admin@blushingowlstudio.com<br />
                Phone : (+66)84-347-0099<br /><br />
                Facebook : BlushingOwlStudio<br />
                Instagram : BlushingOwlStudio<br />
                Line ID : BlushingOwlStudio<br />
                WeChat ID : BlushingOwlStudio<br />
                Weibo ID : BlushingOwlStudio<br />
              </p>
            </div>
            <div class="column">
              <div class="content-padding">
                {{ $t("special.submit_form_detail") }}
              </div>
              <contact-form :from_view="'special'" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/forms/ContactForm.vue";

export default {
  name: "About",
  components: {
    ContactForm
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.no-padding-bottom {
  padding-bottom: 0;
}
.content-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
